import {
  Document,
  PDFViewer,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import React from "react";
import { Products } from "../../components/Products";
import moment from "moment";
import CheckPNG from "../../../assets/check.png";
import pflegeimkarton_sign from "../../../assets/pflegeimkarton_sign.png";
import { capitalize } from "../../../utils/str";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: "30px 40px",
  },
  title: {
    fontSize: 12,
    marginBottom: 15,
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    backgroundColor: "lightgray",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "3px 5px",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "3px 5px",
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: "extrabold",
    padding: "5px 2px",
  },
  tableCell: {
    fontSize: 8,
    padding: "5px 2px",
  },
  nestedTable: {
    marginTop: 2,
  },
  nestedRow: {
    flexDirection: "row",
  },
  nestedCol: {
    borderStyle: "solid",
    borderWidth: 1,
    padding: "3px 5px",
    padding: 2,
  },
  nestedCell: {
    fontSize: 7,
  },
});

const OrderPdfDocument = ({ selectedDate, row }) => {
  var orderProducts = {};
  JSON.parse(row.products).map((product) => {
    if (orderProducts[product.title]) {
      orderProducts[product.title] = {
        ...orderProducts[product.title],
        quantity: orderProducts[product.title].quantity + 1,
      };
    } else {
      product["quantity"] = 1;
      orderProducts[product.title] = product;
    }
  });
  var data = [];
  var specialData = [];
  Products.map((item) => {
    Object.entries(orderProducts).map((product) => {
      if (item.title == product[0]) {
        item["quantity"] = product[1].quantity;
      }
    });
    if (item.title == "Saugende Bettschutzeinlage / wiederverwendbar") {
      specialData.push({
        ...item,
      });
    } else {
      data.push({
        ...item,
      });
    }
  });
  let birthDay = moment(row.startDate).format("DDMMYYYY");
  let currentDate = moment(selectedDate).format("DDMMYYYY");
  birthDay = birthDay.toString();
  currentDate = currentDate.toString();
  let IK = "330508979";

  return (
    // <PDFViewer width="100%" style={{ height: "100vh" }}>
    <Document>
      <Page style={styles.page}>
        <Text style={{ ...styles.title, fontWeight: 800 }}>
          Anlage 2 - Antrag auf Kostenübernahme und Beratungsdokumentation
        </Text>
        <View
          style={{
            ...styles.table,
            border: 0,
            marginBottom: 20,
            marginTop: 25,
          }}
        >
          <View style={{ ...styles.tableRow, border: 0, gap: "10px" }}>
            <View style={{ width: "30%" }}>
              <Text
                style={{
                  borderBottom: "1px solid black",
                  margin: "5px 0 0 0",
                  fontSize: "10px",
                }}
              >
                {row.lastname}, {row.firstname}
              </Text>
              <Text
                style={{
                  ...styles.tableCell,
                  padding: 0,
                  margin: "2px 0 0 0 ",
                }}
              >
                Name, Vorname
              </Text>
            </View>
            <View style={{ width: "30%" }}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                {[...birthDay].map((i) => {
                  return (
                    <Text
                      key={i + "-birthdate"}
                      style={{
                        ...styles.nestedCol,
                        width: "12.25%",
                        height: 17,
                        fontSize: "10px",
                        textAlign: "center",
                      }}
                    >
                      {i}
                    </Text>
                  );
                })}
              </View>
              <Text
                style={{
                  ...styles.tableCell,
                  padding: 0,
                  margin: "2px 0 0 0 ",
                }}
              >
                Geburtsdatum
              </Text>
            </View>
            {row.insuranceNo && row.insurance !== "privat" && (
              <View style={{ width: "40%" }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  {[...row.insuranceNo].map((i) => {
                    return (
                      <Text
                        key={i + "-IK"}
                        style={{
                          ...styles.nestedCol,
                          width: "12.25%",
                          height: 17,
                          fontSize: "10px",
                          textAlign: "center",
                        }}
                      >
                        {i}
                      </Text>
                    );
                  })}
                </View>
                <Text
                  style={{
                    ...styles.tableCell,
                    padding: 0,
                    margin: "2px 0 0 0 ",
                  }}
                >
                  Versichertennummer
                </Text>
              </View>
            )}
          </View>
        </View>
        <View
          style={{
            ...styles.table,
            border: 0,
          }}
        >
          <View style={{ display: "flex", flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 10,
              }}
            >
              <Text style={{ fontSize: 10, paddingRight: 5 }}>
                {row.street +
                  ", " +
                  row.houseNo +
                  " " +
                  row.postcode +
                  " " +
                  row.location}
                ; {row.phone}
              </Text>
            </View>
            <View
              style={{
                width: "30%",
                display: "flex",
                flexWrap: "wrap",
                fontSize: 10,
              }}
            >
              <Text style={{ fontSize: 10 }}>
                {row.insurance ? capitalize(row.insurance) : "Privatversichert"}
              </Text>
            </View>
          </View>
          <View
            style={{
              ...styles.tableRow,
              borderTopWidth: 1,
              borderStyle: "solid",
              marginBottom: 18,
            }}
          >
            <View
              style={{
                ...styles.tableColHeader,
                backgroundColor: "transparent",
                padding: 0,
                margin: "2px 0 0 0 ",
                width: "70%",
                border: 0,
              }}
            >
              <Text style={styles.tableCellHeader}>
                Anschrift: Straße, PLZ, Wohnort
              </Text>
            </View>
            <View
              style={{
                ...styles.tableColHeader,
                backgroundColor: "transparent",
                padding: 0,
                margin: "2px 0 0 0 ",
                width: "30%",
                border: 0,
              }}
            >
              <Text style={styles.tableCellHeader}>Pflegekasse</Text>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 18 }}>
          <Text
            style={{
              fontWeight: "bold",
              padding: 0,
              fontSize: 12,
            }}
          >
            Ich beantrage die Kostenübernahme für:
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 3,
              marginTop: "10px",
              flexWrap: "wrap",
            }}
          >
            <View
              style={{
                border: "1px solid black",
                padding: "1px",
                marginRight: "5px",
                height: "10px",
              }}
            >
              <Image
                source={CheckPNG}
                style={{ width: "8px", height: "8px" }}
              />
            </View>
            <Text
              style={{
                fontSize: 8,
                fontWeight: "extralight",
                width: "90%",
              }}
            >
              zum Verbrauch bestimmte Pflegehilfsmittel – Produktgruppe (PG 54)
              – bis maximal des monatlichen Höchstbetrages nach § 40 Absatz 2
              SGB XI/bei Beihilfeberechtigung bis maximal der Hälfte des
              monatlichen Höchstbetrages nach § 40 Absatz 2 SGB XI.
              <Text style={{ fontWeight: "extrabold", fontSize: 11 }}>
                Darüber hinausgehende Kosten werden von mir selbst getragen.
              </Text>
            </Text>
          </View>
        </View>
        {/* 1st table */}
        <View style={{ ...styles.table, marginBottom: 18 }}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader, width: "40%" }}>
              <Text style={styles.tableCellHeader}>Bezeichnung</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "20%" }}>
              <Text style={styles.tableCellHeader}>
                Pflegehilfsmittelpositionsnummer
              </Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "10%" }}>
              <Text style={styles.tableCellHeader}>Rechengröße</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "10%" }}>
              <Text style={styles.tableCellHeader}>
                Menge/Faktor bitte eintragen
              </Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "20%" }}>
              <Text style={styles.tableCellHeader}>Erläuterung</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableColHeader,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Text
                style={{ ...styles.tableCellHeader, fontWeight: "extrabold" }}
              >
                Zum Verbrauch bestimmte Pflegehilfsmittel (PG 54)
              </Text>
            </View>
          </View>
          {data.map((product, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={{ ...styles.tableCol, width: "40%" }}>
                <Text style={styles.tableCell}>{product.title}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "20%" }}>
                <Text style={styles.tableCell}>{product.barcode}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}>
                  {product.min_stock}
                  {product.barcode == "54.99.02.0001" ||
                  product.barcode == "54.99.02.0002"
                    ? " ml"
                    : " Stück"}
                </Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <View style={styles.nestedTable}>
                  <View style={{ ...styles.nestedRow }}>
                    {product.quantity &&
                      (product.quantity *
                        (product.bundle_qty / product.min_stock) <
                      10 ? (
                        <>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          {[
                            ...(
                              product.quantity *
                              (product.bundle_qty / product.min_stock)
                            ).toString(),
                          ].map((e) => {
                            return (
                              <View
                                style={{
                                  ...styles.nestedCol,
                                  width: "13px",
                                  textAlign: "center",
                                }}
                              >
                                <Text style={styles.nestedCell}>{e}</Text>
                              </View>
                            );
                          })}
                        </>
                      ) : product.quantity *
                          (product.bundle_qty / product.min_stock) <
                        99 ? (
                        <>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          {[
                            ...(
                              product.quantity *
                              (product.bundle_qty / product.min_stock)
                            ).toString(),
                          ].map((e) => {
                            return (
                              <View
                                style={{
                                  ...styles.nestedCol,
                                  width: "13px",
                                  textAlign: "center",
                                }}
                              >
                                <Text style={styles.nestedCell}>{e}</Text>
                              </View>
                            );
                          })}
                        </>
                      ) : (
                        [
                          ...(
                            product.quantity *
                            (product.bundle_qty / product.min_stock)
                          ).toString(),
                        ].map((e) => {
                          return (
                            <View
                              style={{
                                ...styles.nestedCol,
                                width: "13px",
                                textAlign: "center",
                              }}
                            >
                              <Text style={styles.nestedCell}>{e}</Text>
                            </View>
                          );
                        })
                      ))}
                  </View>
                </View>
              </View>
              {index == 2 || index == 3 ? (
                index == 2 ? (
                  <View
                    style={{
                      ...styles.tableCol,
                      width: "20%",
                      borderBottom: 0,
                    }}
                  >
                    <Text style={styles.tableCell}>
                      Bei 500 ml bitte den Faktor 5 bei
                    </Text>
                  </View>
                ) : (
                  <View
                    style={{ ...styles.tableCol, width: "20%", borderTop: 0 }}
                  >
                    <Text style={styles.tableCell}>
                      1000 ml bitte den Faktor 10 eintragen
                    </Text>
                  </View>
                )
              ) : (
                <View style={{ ...styles.tableCol, width: "20%" }}>
                  <Text style={styles.tableCell}></Text>
                </View>
              )}
            </View>
          ))}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
            marginBottom: 18,
            marginTop: "10px",
          }}
        >
          <View
            style={{
              border: "1px solid black",
              padding: "1px",
              marginRight: "5px",
              height: "10px",
            }}
          >
            <Image source={CheckPNG} style={{ width: "8px", height: "8px" }} />
          </View>
          <Text
            style={{
              fontSize: 8,
            }}
          >
            Pflegehilfsmittel zur Körperpflege/Körperhygiene (PG 51) unter Abzug
            der gesetzlichen Zuzahlung, soweit keine Befreiung vorliegt.
          </Text>
        </View>
        {/* 2nd table */}
        <View style={{ ...styles.table, marginBottom: 18 }}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader, width: "20%" }}>
              <Text style={styles.tableCellHeader}>Bezeichnung</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "40%" }}>
              <Text style={styles.tableCellHeader}>
                Pflegehilfsmittelpositionsnummer
              </Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "10%" }}>
              <Text style={styles.tableCellHeader}>Rechengröße</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "10%" }}>
              <Text style={styles.tableCellHeader}>Menge eintragen</Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "20%" }}>
              <Text style={styles.tableCellHeader}>Erläuterung</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View
              style={{
                ...styles.tableColHeader,
                width: "100%",
                textAlign: "center",
              }}
            >
              <Text
                style={{ ...styles.tableCellHeader, fontWeight: "extrabold" }}
              >
                Pflegehilfsmittel zur Körperpflege/Hygiene und zur Linderung von
                Beschwerden (PG 51)
              </Text>
            </View>
          </View>
          {specialData.map((product, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={{ ...styles.tableCol, width: "40%" }}>
                <Text style={styles.tableCell}>{product.title}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "20%" }}>
                <Text style={styles.tableCell}>{product.barcode}</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <Text style={styles.tableCell}>{product.min_stock} Stück</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "10%" }}>
                <View style={styles.nestedTable}>
                  <View style={{ ...styles.nestedRow }}>
                    {product.quantity &&
                      (product.quantity *
                        (product.bundle_qty / product.min_stock) <
                      10 ? (
                        <>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          {[
                            ...`${
                              row.pg51Amount
                                ? row.pg51Amount
                                : product.quantity *
                                  (product.bundle_qty / product.min_stock)
                            }`,
                          ].map((e) => {
                            return (
                              <View
                                style={{
                                  ...styles.nestedCol,
                                  width: "13px",
                                  textAlign: "center",
                                }}
                              >
                                <Text style={styles.nestedCell}>{e}</Text>
                              </View>
                            );
                          })}
                        </>
                      ) : product.quantity *
                          (product.bundle_qty / product.min_stock) <
                        99 ? (
                        <>
                          <View
                            style={{
                              ...styles.nestedCol,
                              width: "13px",
                              textAlign: "center",
                            }}
                          >
                            <Text style={styles.nestedCell}>0</Text>
                          </View>
                          {[
                            ...(
                              product.quantity *
                              (product.bundle_qty / product.min_stock)
                            ).toString(),
                          ].map((e) => {
                            return (
                              <View
                                style={{
                                  ...styles.nestedCol,
                                  width: "13px",
                                  textAlign: "center",
                                }}
                              >
                                <Text style={styles.nestedCell}>{e}</Text>
                              </View>
                            );
                          })}
                        </>
                      ) : (
                        [
                          ...(
                            product.quantity *
                            (product.bundle_qty / product.min_stock)
                          ).toString(),
                        ].map((e) => {
                          return (
                            <View
                              style={{
                                ...styles.nestedCol,
                                width: "13px",
                                textAlign: "center",
                              }}
                            >
                              <Text style={styles.nestedCell}>{e}</Text>
                            </View>
                          );
                        })
                      ))}
                  </View>
                </View>
              </View>
              <View style={{ ...styles.tableCol, width: "20%" }}>
                <Text style={styles.tableCell}></Text>
              </View>
            </View>
          ))}
        </View>
        <View break={true}>
          <Text
            style={{
              fontWeight: "bold",
              padding: 0,
              fontSize: 12,
              marginBottom: 4,
            }}
          >
            durch folgenden Leistungserbringer:
          </Text>
        </View>
        <View style={{ ...styles.table, marginBottom: 18 }}>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableColHeader, width: "55%" }}>
              <Text style={styles.tableCellHeader}>
                Name und Anschrift (Stempel)
              </Text>
            </View>
            <View style={{ ...styles.tableColHeader, width: "45%" }}>
              <Text style={styles.tableCellHeader}>
                Institutionskennzeichen
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={{ ...styles.tableCol, width: "55%" }}>
              <Text style={styles.tableCell}>
                Pflege im Karton/ inobis Care GmbH
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "45%" }}>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {[...IK].map((i) => {
                  return (
                    <Text
                      key={i + "-IK"}
                      style={{
                        ...styles.nestedCol,
                        width: 20,
                        fontSize: 10,
                        textAlign: "center",
                      }}
                    >
                      {i}
                    </Text>
                  );
                })}
                <Text
                  style={{
                    ...styles.nestedCol,
                    width: 20,
                    fontSize: 10,
                    textAlign: "center",
                  }}
                >
                  0
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginBottom: 18 }}>
          <View
            style={{
              fontSize: 8,
              fontWeight: "extralight",
              listStyleType: "square",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: 3,
            }}
          >
            <View
              style={{
                border: "1px solid black",
                padding: "1px",
                marginRight: "5px",
                height: "10px",
              }}
            >
              <Image
                source={CheckPNG}
                style={{ width: "8px", height: "8px" }}
              />
            </View>
            <Text>
              Ich wurde vor der Übergabe des Pflegehilfsmittels/der
              Pflegehilfsmittel von dem vorgenannten Leistungserbringer
            </Text>
          </View>
          <Text
            style={{
              fontSize: 8,
              fontWeight: "extralight",
            }}
          >
            umfassend beraten, insbesondere darüber
          </Text>
          <View
            style={{
              marginTop: 5,
              marginLeft: 10,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Text style={{ width: "5%", marginTop: -10 }}>-</Text>
              <Text
                style={{
                  fontSize: 8,
                  width: "95%",
                }}
              >
                welche Produkte und Versorgungsmöglichkeiten für meine konkrete
                Versorgungssituation geeignet und notwendig sind,
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Text style={{ width: "5%", marginTop: -10 }}>-</Text>
              <Text
                style={{
                  fontSize: 8,
                  width: "95%",
                }}
              >
                die ich ohne Mehrkosten erhalten kann.
              </Text>
            </View>
          </View>
        </View>
        <View style={{ ...styles.table, marginBottom: 18, border: 0 }}>
          <View style={{ ...styles.tableRow, border: 0 }}>
            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <Text style={styles.tableCell}>Form des Beratungsgesprächs:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    marginRight: "5px",
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  Beratung in den Geschäftsräumen
                </Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  padding: "3px 0px",
                }}
              >
                <View
                  style={{
                    border: "1px solid black",
                    padding: "1px",
                    marginRight: "5px",
                    height: "10px",
                  }}
                >
                  <Image
                    source={CheckPNG}
                    style={{ width: "8px", height: "8px" }}
                  />
                </View>
                <Text style={{ fontSize: 8 }}>
                  Individuelle telefonische oder digitale Beratung (z. B.
                  Videochat)
                </Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    marginRight: "5px",
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  Beratung in der Häuslichkeit
                </Text>
              </View>
            </View>
          </View>
          <View style={{ ...styles.tableRow, border: 0, marginTop: 10 }}>
            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <Text style={styles.tableCell}>
                Der o. g. Leistungserbringer hat
              </Text>
            </View>
            <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    border: "1px solid black",
                    padding: "1px",
                    marginRight: "5px",
                    height: "10px",
                  }}
                >
                  <Image
                    source={CheckPNG}
                    style={{ width: "8px", height: "8px" }}
                  />
                </View>
                <Text style={{ fontSize: 8 }}>mich persönlich und/oder</Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "3px 0px",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                    marginRight: "5px",
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  meine Betreuungsperson (ges. Vertreter/Bevollmächtigten oder
                </Text>
              </View>
              <Text style={{ fontSize: 8 }}>Angehörigen) beraten.</Text>
            </View>
          </View>
          <View style={{ ...styles.tableRow, border: 0, marginTop: 10 }}>
            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <Text style={styles.tableCell}>Datum der Beratung:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {[...currentDate].map((item, i) => {
                  return (
                    <Text
                      key={i + "currentDate1"}
                      style={{
                        // ...styles.nestedCol,
                        width: 20,
                        fontSize: 10,
                        textAlign: "center",
                        orderStyle: "solid",
                        borderWidth: 1,
                        padding: "3px 5px",
                        padding: 2,
                      }}
                    >
                      {item}
                    </Text>
                  );
                })}
              </View>
            </View>
          </View>
          <View style={{ ...styles.tableRow, border: 0, marginTop: 12 }}>
            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <Text style={styles.tableCell}>Beratende/r Mitarbeiter/in:</Text>
            </View>
            <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
              <Image source={pflegeimkarton_sign} style={{ width: 100 }} />
              <Text style={{ borderBottom: 1, borderStyle: "solid" }}></Text>
            </View>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              border: "1px solid black",
              padding: "1px",
              marginRight: "5px",
              height: "10px",
            }}
          >
            <Image source={CheckPNG} style={{ width: "8px", height: "8px" }} />
          </View>
          <Text
            style={{
              fontSize: 8,
              fontWeight: "extralight",
            }}
          >
            <Text style={{ fontWeight: "extrabold", fontSize: 11 }}>
              Mit meiner Unterschrift bestätige ich, dass ich darüber informiert
              wurde, dass die gewünschten
            </Text>
          </Text>
        </View>
        <Text style={{ fontWeight: "extrabold", fontSize: 11 }}>
          Produkte ausnahmslos für die häusliche Pflege durch eine private
          Pflegeperson (und nicht durch
        </Text>
        <Text style={{ fontWeight: "extrabold", fontSize: 11 }}>
          Pflegedienste oder Einrichtungen der Tagespflege) verwendet werden
          dürfen.
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            alignItems: "flex-start",
            marginTop: 10,
          }}
        >
          <View
            style={{
              border: "1px solid black",
              padding: "1px",
              marginRight: "5px",
              height: "10px",
            }}
          >
            <Image source={CheckPNG} style={{ width: "8px", height: "8px" }} />
          </View>
          <Text
            style={{
              fontSize: 9,
            }}
          >
            <Text style={{ fontWeight: "extralight" }}>
              Ich bin darüber aufgeklärt worden, dass die Pflegekasse die Kosten
              nur für solche Pflegehilfsmittel und in dem
            </Text>
          </Text>
        </View>
        <Text style={{ fontWeight: "extralight", fontSize: 9 }}>
          finanziellen Umfang übernimmt, für die ich eine
          Kostenübernahmeerklärung durch die Pflegekasse erhalten habe.
        </Text>
        <Text style={{ fontWeight: "extralight", fontSize: 9 }}>
          Kosten für evtl. darüber hinausgehende Leistungen sind von mir selbst
          zu tragen.
        </Text>
        <View style={{ ...styles.table, marginBottom: 12, border: 0 }}>
          <View
            style={{
              ...styles.tableRow,
              border: 0,
              marginTop: 10,
              alignItems: "flex-end",
            }}
          >
            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <View style={{ display: "flex", flexDirection: "row" }}>
                {[...currentDate].map((item, i) => {
                  return (
                    <Text
                      key={i + "currentDate12"}
                      style={{
                        // ...styles.nestedCol,
                        width: 20,
                        fontSize: 10,
                        textAlign: "center",
                        orderStyle: "solid",
                        borderWidth: 1,
                        padding: "3px 5px",
                        padding: 2,
                      }}
                    >
                      {item}
                    </Text>
                  );
                })}
              </View>
              <Text
                style={{
                  ...styles.tableCell,
                  padding: 0,
                  margin: "2px 0 0 0 ",
                }}
              >
                Datum
              </Text>
            </View>
            {row.insurance !== "privat" ? (
              <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
                <Image source={row.signImage} style={{ height: 60 }} />
                <Text style={{ borderBottom: 1, borderStyle: "solid" }}></Text>
                <Text
                  style={{
                    ...styles.tableCell,
                    padding: 0,
                    margin: "2px 0 0 0 ",
                  }}
                >
                  Unterschrift der/des Versicherten
                </Text>
              </View>
            ) : null}
          </View>
        </View>
        <View style={{ marginBottom: 12 }}>
          <Text
            style={{
              marginTop: 10,
              fontSize: 8,
            }}
          >
            <Text style={{ fontWeight: "extrabold" }}>
              *Unterschrift der Betreuungsperson oder des gesetzl. Vertreters
              bei Personen, die das 18. Lebensjahr noch nicht vollendet haben
            </Text>
          </Text>
        </View>
        <View
          style={{
            ...styles.table,
            border: 0,
          }}
        >
          <View
            style={{
              ...styles.tableRow,
              padding: "5px 0px",
              marginTop: 10,
              display: "flex",
              flexWrap: "wrap",
              borderWidth: 1,
              borderStyle: "solid",
            }}
          >
            <View
              style={{
                ...styles.tableCol,
                width: "55%",
                border: 0,
                paddingRight: 20,
              }}
            >
              <Text
                style={{
                  ...styles.tableCell,
                  fontSize: 12,
                  fontWeight: 800,
                  textDecoration: "underline",
                }}
              >
                Genehmigungsvermerk der Pflegekasse
              </Text>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  marginTop: 15,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>PG 54</Text>
              </View>
              <Text style={{ fontSize: 8, marginTop: 5 }}>
                bis maximal des monatlichen Höchstbetrages
              </Text>
              <Text style={{ fontSize: 8, marginTop: 5 }}>
                nach § 40 Absatz 2 SGB XI
              </Text>
            </View>

            <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  marginTop: 5,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>PG 51 mit Zuzahlung</Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  marginTop: 5,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>PG 51 ohne Zuzahlung</Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  marginTop: 5,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  {" "}
                  PG 51 mit Zuzahlung/Beihilfeberechtigter
                </Text>
              </View>
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  marginTop: 5,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  PG 51 ohne Zuzahlung/Beihilfeberechtigter
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableRow,
                width: "100%",
                border: 0,
                padding: "0px 5px",
                marginTop: 5,
              }}
            >
              <View
                style={{
                  ...styles.nestedTable,
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    width: 12,
                    height: 12,
                    borderStyle: "solid",
                    borderWidth: 1,
                  }}
                ></Text>
                <Text style={{ fontSize: 8 }}>
                  PG 54 Beihilfeberechtigung bis maximal der Hälfte des
                  monatlichen Höchstbetrages nach § 40 Absatz 2 SGB XI
                </Text>
              </View>
            </View>
            <View
              style={{
                ...styles.tableRow,
                width: "100%",
                border: 0,
                padding: "0px 5px",
                marginTop: 40,
              }}
            >
              <View style={{ ...styles.tableCol, width: "55%", border: 0 }}>
                <Text style={{ fontSize: 10 }}>(Datum)</Text>
              </View>
              <View style={{ ...styles.tableCol, width: "45%", border: 0 }}>
                <Text style={{ fontSize: 10 }}>
                  (IK der Pflegekasse, Stempel und Unterschrift)
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default OrderPdfDocument;
