import React, { useContext, useEffect, useState, useRef } from "react";
import { BsArrowLeft } from "react-icons/bs";
import DeliverSVG from "../../assets/svgs/deliver.svg";
import DatePicker from "react-datepicker";
import SignatureCanvas from "react-signature-canvas";
import { ConnectContext } from "../../provider/ConnectProvider";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Insurances } from "../components/Insurances";

export function range(start, end) {
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}

const Section = () => {
  const sigCanvas = useRef();
  const navigation = useNavigate();
  const [signError, setSignError] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  // const [checkSecond, setCheckSecond] = useState(false);
  const [checkThird, setCheckThird] = useState(false);
  const [canvasIsDirty, setCanvasIsDirty] = useState(false);

  const years = range(1901, new Date().getFullYear() + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const {
    health,
    insurance,
    insuranceNo,
    startDate,
    setHealth,
    setInsurance,
    setStartDate,
    setInsuranceNo,
    setSignImage,
    setCompletedThirdStep,
    completedFirstStep,
    completedSecondStep,
  } = useContext(ConnectContext);

  const healthIsPrivate = showInput;

  const disabled = !(
    (healthIsPrivate ? true : insurance) &&
    startDate &&
    checkPrivacyPolicy &&
    checkThird &&
    canvasIsDirty
  );

  useEffect(() => {
    if (!completedSecondStep || !completedSecondStep) {
      navigation("/order/select");
    }
  }, [completedFirstStep, completedSecondStep]);

  return (
    <div className="bg-[#f4f6fa] w-[100%] pb-[100px] pt-[50px]">
      <div className="max-w-[1250px] flex flex-col justify-center items-start -lg:items-center space-x-0 space-y-4 mx-auto px-12 -sm:px-4">
        {/* <p className='text-2xl text-[#6ea13b] font-bold'>Vielen Dank für Ihre Bestellung!</p>
        <div className='flex flex-row justify-start items-center space-x-2 -sm:px-2 text-justify w-full'>
          <img src={DeliverSVG} className='w-[24px] h-[24px]' />
          <div className='text-[14px] w-full'>
            <strong className='pr-1'>Ihre Lieferung wird in 3-5 Werktagen bei ihnen eintreffen.</strong>
            Nach Versand erhalten Sie eine Versandbestätigung per Email.
          </div>
        </div> */}
        <p className="text-[#b91c1c] text-[14px] font-bold">
          Bitte vervollständigen Sie jetzt noch Ihre Daten:
        </p>

        <div className="flex flex-col justify-center items-start space-y-8 bg-white w-full p-8 rounded-lg text-left">
          <p className="font-bold">
            Versicherteninformationen der pflegebedürftigen Person
          </p>
          <div className="flex flex-col justify-start items-start space-y-4 w-[70%] -md:w-full">
            <div className="flex flex-row justify-start items-center space-x-8">
              <label>
                <input
                  type="checkbox"
                  checked={!showInput ? true : false}
                  onChange={() => {
                    setShowInput(false);
                  }}
                />
                <span>gesetzlich</span>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={showInput ? true : false}
                  onChange={() => {
                    setShowInput(true);
                    setInsurance("privat");
                  }}
                />
                <span>privat</span>
              </label>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2 w-full">
              {!healthIsPrivate && (
                <>
                  <p className="text-[12px]">
                    Gesetzliche Krankenkasse/Pflegekasse *
                  </p>
                  <select
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    className="w-full border border-gray-300 rounded-lg px-2 py-2 text-[12px]"
                  >
                    <option value="" disabled={true}>
                      Bitte auswählen
                    </option>
                    {Insurances.sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    }).map((item, index) => {
                      return (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </>
              )}
            </div>

            <div className="flex flex-row -md:flex-col justify-start items-start space-x-4 -md:space-x-0 -md:space-y-4 w-full">
              {!healthIsPrivate && (
                <>
                  {showInput ? (
                    <div className="flex flex-col justify-start items-start space-y-2 w-[60%] -md:w-full">
                      <p className="text-[12px]">Versicherung/Krankenkasse *</p>
                      <input
                        type="text"
                        name="health"
                        value={health || ""}
                        className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[12px]"
                        onChange={(e) => {
                          setHealth(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col justify-start items-start space-y-2 w-[60%] -md:w-full">
                      <p className="text-[12px]">Versichertennummer *</p>
                      <input
                        type="text"
                        name="insuranceNo"
                        value={insuranceNo || ""}
                        className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[12px]"
                        onChange={(e) => {
                          setInsuranceNo(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="flex flex-col justify-start items-start space-y-2 w-[40%] -md:w-full">
                <p className="text-[12px]">Geburtstag *</p>
                {/* <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className='w-full border border-gray-300 rounded-lg px-4 py-2 text-[12px]'
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    showFullMonthYearPicker
                  /> */}
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  className="w-full border border-gray-300 rounded-lg px-4 py-2 text-[12px]"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      className="space-x-2"
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        className="border px-2 py-1 rounded-[5px]"
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[date.getMonth()]}
                        className="border px-2 py-1 rounded-[5px]"
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start space-y-4 bg-white w-full p-8 rounded-lg text-left">
          <p className="font-bold">Unterschrift des Ansprechpartners *</p>
          <p className="text-[12px]">
            Hiermit bestätige ich die Beantragung der Pflegehilfsmittel und
            deren Belieferung durch Pflege im Karton.
          </p>
          <div
            className={`w-full border rounded-lg ${
              signError ? "border-[#de1009]" : ""
            }`}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{ className: "sigCanvas" }}
              onEnd={() => setCanvasIsDirty(true)}
              ref={sigCanvas}
              clearOnResize={false}
            />
          </div>
          {signError && (
            <p className="text-[#de1009] text-[12px] font-bold">{signError}</p>
          )}
          <div className="w-full flex flex-row -sm:flex-col justify-end -sm:justify-center items-center space-x-2 -sm:space-x-0 -sm:space-y-2">
            <p className="text-[12px] text-gray-600">
              Benutzen Sie Maus, Trackpad oder Ihren Finger, um Ihre
              Unterschrift in das oben stehende Feld zu zeichnen.
            </p>
            <button
              onClick={() => {
                sigCanvas.current.clear();
                setCanvasIsDirty(false);
              }}
              className="px-8 py-2 border border-black rounded-lg text-[12px] font-bold"
            >
              Zurücksetzen
            </button>
          </div>
          <div className="flex flex-col justify-start items-start space-y-2 w-full">
            <label className="px-4">
              <input
                type="checkbox"
                checked={checkPrivacyPolicy}
                onChange={() => {
                  setCheckPrivacyPolicy(!checkPrivacyPolicy);
                }}
              />
              <span className="text-[13px]">
                Ich habe die{" "}
                <a
                  href="https://pflegeimkarton.de/datenschutzbestimmungen"
                  target="_blank"
                  className="underline"
                >
                  Datenschutzbestimmungen
                </a>{" "}
                gelesen und bin einverstanden. *
              </span>
            </label>
            <div className="flex flex-col justify-start items-start space-y-2 rounded-lg w-full px-4 py-2">
              <label>
                <input
                  type="checkbox"
                  checked={checkThird}
                  onChange={() => {
                    setCheckThird(!checkThird);
                  }}
                />
                <span className="text-[13px]">
                  Ich möchte ab sofort{" "}
                  <a
                    href="https://pflegeimkarton.de/erklaerung-zum-erhalt-von-pflegehilfsmitteln-und-antrag-aufkostenuebernahme-und-beratungsdokumentation/"
                    target="_blank"
                    className="underline"
                  >
                    von Pflege im Karton versorgt werden. *
                  </a>
                </span>
              </label>
              {/* <label>
                <input 
                  type="checkbox" 
                  checked={checkSecond ? true : false} 
                  onChange={() => { 
                    setCheckSecond(!checkSecond) 
                    setCheckThird(false)
                  }} 
                />
                <span className='text-[13px]'>
                  Ich erhalte bereits Pflegehilfsmittel von einem anderen Versorger, möchte jedoch zukünftig von Pflege im Karton beliefert werden.
                </span>
              </label> */}
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row justify-end items-center space-x-4">
          <div
            className="flex flex-row justify-start items-center space-x-2 cursor-pointer"
            onClick={() => {
              navigation("/order/data-entry");
            }}
          >
            <BsArrowLeft />
            <p className="text-[12px] font-bold">Zurück</p>
          </div>
          <div
            className={`flex flex-row justify-center items-center rounded-[10px] py-2 px-8 ${
              disabled
                ? "bg-[#d1d5db] cursor-not-allowed"
                : "bg-[#f98918] hover:bg-[#ffc04f] hover:text-black"
            } text-[14px] text-white font-bold cursor-pointer`}
            onClick={() => {
              if (disabled) return;
              const URL = sigCanvas.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
              if (sigCanvas.current.isEmpty()) {
                setSignError("Bitte geben Sie Ihre Unterschrift!");
                return;
              } else {
                setSignError(null);
              }
              setSignImage(URL);
              setCompletedThirdStep(true);
              navigation("/order/thank-you");
            }}
          >
            Gehen Sie zur Bestellübersicht
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
