import React, { useRef, useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { CgSpinner } from "react-icons/cg";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import DatePicker from "react-datepicker";
import Section from "../../landing/Section";
import { ConnectContext } from "../../../provider/ConnectProvider";
import { range } from "../../sign/Section";
import { Insurances } from "../../components/Insurances";
import CloseSVG from "../../../assets/svgs/close.svg";
import { required, vusername, validEmail } from "../../../utils";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "../../../pages/data/data.css";
import { tryParseInt } from "../../../utils/tryParse";

const degrees = [1, 2, 3, 4, 5, 0];

const OrderModal = ({ onClose, selectedOrder, fetchOrders }) => {
  const form = useRef();
  const checkBtn = useRef();
  const { successAlert, setSelectedProducts, selectedProducts } =
    useContext(ConnectContext);
  const { user: currentUser } = useSelector((state) => state.auth);

  const years = range(1901, new Date().getFullYear() + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const [insuranceError, setInsuranceError] = useState(null);
  const [startDateError, setStartDateError] = useState(null);
  const [showInput, setShowInput] = useState(false);

  // order fields define with state variable
  const [type, setType] = useState(selectedOrder?.type || "man");
  const [pg51Amount, setPG51Amount] = useState(selectedOrder?.pg51Amount || 0);
  const [pg51, setPG51] = useState(selectedOrder?.pg51 || false);
  const [pg54, setPG54] = useState(selectedOrder?.pg54 || false);
  const [interval, setInterval] = useState(selectedOrder?.interval || 1);
  const [firstname, setFirstname] = useState(selectedOrder?.firstname || "");
  const [lastname, setLastname] = useState(selectedOrder?.lastname || "");
  const [street, setStreet] = useState(selectedOrder?.street || "");
  const [houseNo, setHouseNo] = useState(selectedOrder?.houseNo || "");
  const [houseNo2, setHouseNo2] = useState(selectedOrder?.houseNo2 || "");
  const [postcode, setPostcode] = useState(selectedOrder?.postcode || "");
  const [location, setLocation] = useState(selectedOrder?.location || "");
  const [street2, setStreet2] = useState(selectedOrder?.street2 || "");
  const [company, setCompany] = useState(selectedOrder?.company || "");
  const [postcode2, setPostcode2] = useState(selectedOrder?.postcode2 || "");
  const [location2, setLocation2] = useState(selectedOrder?.location2 || "");
  const [degree, setDegree] = useState(selectedOrder?.degree || "1");
  const [phone, setPhone] = useState(selectedOrder?.phone || "");
  const [email, setEmail] = useState(selectedOrder.email || "");
  const [referral, setReferral] = useState(selectedOrder?.referral || "");
  const [health, setHealth] = useState(selectedOrder?.health || "");
  const [insurance, setInsurance] = useState(selectedOrder?.insurance || "");
  const [startDate, setStartDate] = useState(selectedOrder?.startDate || "");
  const [insuranceNo, setInsuranceNo] = useState(
    selectedOrder?.insuranceNo || ""
  );
  const [degreeWarning, setDegreeWarning] = useState(null);
  const [showAddress, setShowAddress] = useState(true);

  useEffect(() => {
    if (selectedOrder?.products) {
      setSelectedProducts(JSON.parse(selectedOrder.products));
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (insurance === "Sonstiges/Privat versichert") {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  }, [insurance]);

  const handleEditOrder = (e) => {
    e.preventDefault();
    setIsLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (!isValidPhoneNumber(phone)) {
        setPhoneError("Invalid phone number");
        return;
      }
      if (!insurance) {
        setInsuranceError("Please select the insurance");
        return;
      }
      if (!startDate) {
        setStartDateError("Please select the birthday");
        return;
      }

      const formData = {
        type,
        interval,
        email,
        firstname,
        lastname,
        street,
        street2,
        company,
        houseNo,
        houseNo2,
        postcode,
        postcode2,
        location,
        location2,
        degree,
        phone,
        health,
        insurance,
        insuranceNo,
        startDate,
        referral,
        pg51,
        pg51Amount: tryParseInt(pg51Amount) || 0,
        pg54,
        orderId: selectedOrder.id,
        products: JSON.stringify(selectedProducts),
      };

      api
        .post("/api/order/update", formData)
        .then((res) => {
          if (res.data.success) {
            successAlert(res.data.message);
            fetchOrders();
            setSelectedProducts([]);
            onClose();
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`fixed h-full w-full top-0 left-0 bg-[rgba(138,148,153,0.7)] bg-opacity-80 flex items-start justify-center z-30 overflow-y-scroll overflow-x-hidden detail-modal`}
    >
      <div className="relative mx-auto rounded-lg bg-white my-[50px] -lg:w-[90%] -sm:w-[98%] lg:w-[1100px]">
        <div className="w-full min-h-[80px] px-8 py-4 border-b-[1px] border-[#e1e4e6] bg-white rounded-tl-lg rounded-tr-lg flex flex-row justify-between items-center">
          <div className="text-[20px] font-RNSSanzBold">
            Bestellung bearbeiten
          </div>
          <div
            className="flex flex-row justify-center items-center bg-[#0000000d] w-[30px] h-[30px] rounded-full cursor-pointer"
            onClick={() => {
              setSelectedProducts([]);
              onClose();
            }}
          >
            <img src={CloseSVG} className="w-[20px] h-[20px]" />
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-center py-4 px-6 -sm:px-2">
          <Section from={"modal"} orderId={selectedOrder?.id} />
          <Form
            onSubmit={handleEditOrder}
            autoComplete="off"
            ref={form}
            className="flex flex-col justify-start itesm-center space-y-4 w-[100%] pt-4"
          >
            <div className="grid grid-cols-2 -sm:grid-cols-1 gap-4">
              <div className="flex flex-col justify-start items-start space-y-2 px-6">
                <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                  PG51
                </p>
                <div className="flex flex-row justify-start items-center space-x-8">
                  <label>
                    <input
                      type="checkbox"
                      checked={pg51}
                      onChange={() => {
                        if (currentUser?.role == "admin") {
                          setPG51(true);
                        }
                      }}
                    />
                    <span>Genehmigt</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={!pg51}
                      onChange={() => {
                        if (currentUser?.role == "admin") {
                          setPG51(false);
                        }
                      }}
                    />
                    <span>Ausstehend</span>
                  </label>
                </div>
                {pg51 === true ? (
                  <div className="form-group w-full">
                    <input
                      placeholder="Anzahl der genehmigten PG51"
                      type="number"
                      className="form-control !bg-white !text-[13px]"
                      name="pg51Amount"
                      onWheel={(e) => {
                        e.target.blur();
                      }}
                      value={pg51Amount}
                      onChange={(e) => setPG51Amount(e.target.value)}
                      validations={pg51 ? [required] : []}
                      autoComplete="off"
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col justify-start items-start space-y-2 px-6">
                <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                  PG54
                </p>
                <div className="flex flex-row justify-start items-center space-x-8">
                  <label>
                    <input
                      type="checkbox"
                      checked={pg54}
                      onChange={() => {
                        if (currentUser?.role == "admin") {
                          setPG54(true);
                        }
                      }}
                    />
                    <span>Genehmigt</span>
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={!pg54}
                      onChange={() => {
                        if (currentUser?.role == "admin") {
                          setPG54(false);
                        }
                      }}
                    />
                    <span>Ausstehend</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2 px-6">
              <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                Anrede *
              </p>
              <div className="flex flex-row justify-start items-center space-x-8">
                <label>
                  <input
                    type="checkbox"
                    checked={type === "woman" ? true : false}
                    onChange={() => {
                      setType("woman");
                    }}
                  />
                  <span>Frau</span>
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={type === "man" ? true : false}
                    onChange={() => {
                      setType("man");
                    }}
                  />
                  <span>Herr</span>
                </label>
              </div>
            </div>
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="firstname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Vorname *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="firstname"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  validations={[required, vusername]}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="lastname"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Nachname *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="lastname"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  validations={[required, vusername]}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="street"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Straße *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  validations={[required]}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="houseNo"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Haus Nummer. *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="houseNo"
                  value={houseNo}
                  onChange={(e) => setHouseNo(e.target.value)}
                  validations={[required]}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6">
              <div className="form-group w-full">
                <label
                  htmlFor="postcode"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  PLZ *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="postcode"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  validations={[required]}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="location"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Ort *
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  validations={[required]}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-2 px-6">
              <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                Bestellrythmus *
              </p>
              <div className="flex flex-row -sm:flex-col justify-start items-center -sm:items-start space-x-8 -sm:space-x-0 -sm:space-y-4">
                <label>
                  <input
                    type="checkbox"
                    checked={interval == 1 ? true : false}
                    onChange={() => {
                      setInterval(1);
                    }}
                  />
                  <span>Monatlich</span>
                </label>
                {/* <label>
                  <input
                    type="checkbox"
                    checked={interval == 2 ? true : false}
                    onChange={() => {
                      setInterval(2);
                    }}
                  />
                  <span>Alle zwei Monate</span>
                </label> */}
                <label>
                  <input
                    type="checkbox"
                    checked={interval == 3 ? true : false}
                    onChange={() => {
                      setInterval(3);
                    }}
                  />
                  <span>Alle drei Monate</span>
                </label>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start space-y-4 w-full border border-gray-300 rounded-lg p-4">
              <div className="bg-[#eff6ff] rounded-[5px] p-4 w-full space-y-4">
                <label>
                  <input
                    type="checkbox"
                    checked={showAddress}
                    onChange={() => {
                      setShowAddress(!showAddress);
                    }}
                  />
                  <span className="text-[#6b7280] font-bold select-none">
                    Abweichende Lieferadresse
                  </span>
                </label>
                <p className="text-[#6b7280] text-[12px]">
                  Die Lieferung soll nicht an die oben angegebene Adresse der
                  pflegebedürftigen Person gesendet werden? Dann geben Sie hier
                  eine abweichende Lieferanschrift an.
                </p>
              </div>
              {showAddress && (
                <div className="w-full flex flex-col justify-start items-start space-y-4">
                  <div className="w-full grid grid-cols-3 -sm:grid-cols-1 gap-2 -sm:gap-4">
                    <div className="form-group w-full">
                      <label
                        htmlFor="company"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Name/Firma
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group w-full">
                      <label
                        htmlFor="street2"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Straße
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="street2"
                        value={street2}
                        onChange={(e) => setStreet2(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group w-full">
                      <label
                        htmlFor="street2"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Haus Nummer
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="houseNo2"
                        value={houseNo2}
                        onChange={(e) => setHouseNo2(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="w-full flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start">
                    <div className="form-group w-full">
                      <label
                        htmlFor="postcode2"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        PLZ
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="postcode2"
                        value={postcode2}
                        onChange={(e) => setPostcode2(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group w-full">
                      <label
                        htmlFor="street2"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Ort
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="location2"
                        value={location2}
                        onChange={(e) => setLocation2(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col justify-center items-start bg-white w-full px-6 rounded-lg text-left pt-4">
              <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                Pflegegrad *
              </p>
              <div className="grid grid-cols-3 -md:grid-cols-2 gap-4 justify-between items-center w-full">
                {degrees.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`border rounded-lg text-center px-8 -sm:px-4 py-3 text-[12px] cursor-pointer ${
                        degree == item && "bg-[#6ea13b] text-white"
                      }`}
                      onClick={() => {
                        setDegree(item);
                        if (item === 0) {
                          setDegreeWarning(
                            "Wenn Sie keinen Pflegegrad haben ist die Bestellung für Sie kostenpflichtig."
                          );
                        } else {
                          setDegreeWarning(null);
                        }
                      }}
                    >
                      {item === 0 ? "Keine" : `Pflegegrad ${item}`}
                    </div>
                  );
                })}
              </div>
              {degreeWarning && (
                <p className="text-[12px] text-[#de1009] mt-2">
                  {degreeWarning}
                </p>
              )}
            </div>
            <div className="w-full flex flex-row -md:flex-col justify-center items-start space-x-4 -md:space-x-0 -md:space-y-4 px-6 pt-4">
              <div className="flex flex-col justify-start items-start w-full">
                <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                  Ihre Telefonnummer *
                </p>
                <PhoneInput
                  defaultCountry="DE"
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  className={`w-full border ${
                    phoneError ? "border-[#de1009]" : "border-gray-300"
                  } rounded-lg px-4 py-1 text-[12px] h-[40px]`}
                />
                {phoneError && (
                  <p className="text-[#de1009] text-[12px] font-bold">
                    {phoneError}
                  </p>
                )}
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="email"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Ihre E-Mail Adresse
                </label>
                <Input
                  type="text"
                  className="form-control !bg-white !text-[13px]"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  // validations={[validEmail]}
                  placeholder={"E-mail Address"}
                  autoComplete="off"
                />
              </div>
              <div className="form-group w-full">
                <label
                  htmlFor="referral"
                  className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                >
                  Vertragspartner Nummer
                </label>
                <Input
                  type="number"
                  className="form-control !bg-white !text-[13px]"
                  name="referral"
                  value={referral}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 3);
                    setReferral(value);
                  }}
                  autoComplete="off"
                />
              </div>
            </div>
            {insurance !== "privat" || insurance === "Beihilfe Stelle 70%" ? (
              <div className="flex flex-col justify-start items-start w-full px-6 pt-4">
                <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                  Gesetzliche Krankenkasse/Pflegekasse *
                </p>
                <select
                  value={insurance}
                  onChange={(e) => setInsurance(e.target.value)}
                  className="w-full border border-gray-300 rounded-lg px-2 py-2 text-[12px]"
                >
                  <option value="" disabled={true}>
                    Bitte auswählen
                  </option>
                  {Insurances.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                  }).map((item, index) => {
                    return (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {insuranceError && (
                  <p className="text-[#de1009] text-[12px] font-bold">
                    {insuranceError}
                  </p>
                )}
              </div>
            ) : null}
            <div className="flex flex-row -sm:flex-col -sm:space-y-2 justify-between space-x-2 -sm:space-x-0 items-start px-6 pt-4">
              {insurance !== "privat" || insurance === "Beihilfe Stelle 70%" ? (
                <>
                  {showInput ? (
                    <div className="form-group w-full">
                      <label
                        htmlFor="health"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Versicherung/Krankenkasse *
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="health"
                        value={health}
                        onChange={(e) => setHealth(e.target.value)}
                        validations={[required]}
                        autoComplete="off"
                      />
                    </div>
                  ) : (
                    <div className="form-group w-full">
                      <label
                        htmlFor="insuranceNo"
                        className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold"
                      >
                        Versichertennummer *
                      </label>
                      <Input
                        type="text"
                        className="form-control !bg-white !text-[13px]"
                        name="insuranceNo"
                        value={insuranceNo}
                        onChange={(e) => setInsuranceNo(e.target.value)}
                        validations={[required]}
                        autoComplete="off"
                      />
                    </div>
                  )}
                </>
              ) : null}
              <div className="flex flex-col justify-start items-start w-[40%] -md:w-full">
                <p className="text-[12px] font-RNSSanzMedium pb-1 text-[#6ea13b] font-bold">
                  Geburtstag *
                </p>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 text-[12px]"
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      className="space-x-2"
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={date.getFullYear()}
                        className="border px-2 py-1 rounded-[5px]"
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[date.getMonth()]}
                        className="border px-2 py-1 rounded-[5px]"
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={new Date(startDate)}
                  onChange={(date) => setStartDate(date)}
                />
                {startDateError && (
                  <p className="text-[#de1009] text-[12px] font-bold">
                    {startDateError}
                  </p>
                )}
              </div>
            </div>

            <div className="form-group flex justify-center">
              <button
                disabled={isLoading}
                className="!h-[40px] !mt-4 !w-[100px]"
              >
                {isLoading ? (
                  <CgSpinner
                    className={`text-white text-[30px] animate-spin`}
                  />
                ) : (
                  <span className="text-white">Speichern</span>
                )}
              </button>
            </div>
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
