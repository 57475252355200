/**
 * @param {string} value
 * @returns {number}
 */
export function tryParseInt(value) {
  try {
    return parseInt(value);
  } catch (e) {
    return 0;
  }
}
