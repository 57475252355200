import React, { useState, useEffect, useMemo, useContext } from "react";
import { CgSpinner } from "react-icons/cg";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Tab } from "@headlessui/react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Products, ProductsBarcode } from "../../components/Products";
import { Insurances } from "../../components/Insurances";
import { pdf } from "@react-pdf/renderer";
import ReactTooltip from "react-tooltip";
import OrderPdfDocument from "./OrderPdfDocument";
import NewPdfDocument from "./ThirdDocument";
import {
  BsFilePdfFill,
  BsTrash,
  BsFillSendFill,
  BsFillFileEarmarkExcelFill,
  BsPencilFill,
} from "react-icons/bs";
import InsurancePdfDocument from "./InsurancePdfDocument";
import ConfirmModal from "./ConfirmModal";
import PDFDownloadModal from "./PDFDownloadModal";
import OrderModal from "./OrderModal";
import { ConnectContext } from "../../../provider/ConnectProvider";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import api from "../../../services/api";
import PDFAllDownloadModal from "./PDFAllDownloadModal";
import PDFDownloadModalForInsurance from "./PDFDownloadModalForInsurance";
import PDFDownloadModalForNew from "./PDFDownloadModalForNew";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const CustomLoader = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center space-y-8 mt-16">
      <CgSpinner className={`text-[#6ea13b] text-[70px] animate-spin`} />
      <span className="text-[16px] font-SenSans text-[#6ea13b]">
        Laden der Bestellliste...
      </span>
    </div>
  );
};

const OrderList = () => {
  return <List />;
};

const List = () => {
  const [curPage, setCurPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadLoading, setIsDownloadLoding] = useState(false);
  const [isDownloadPDF1Loading, setIsDownloadPDF1Loading] = useState(false);
  const [percentPDF1, setPercentPDF1] = useState(0);
  const [isDownloadPDF2Loading, setIsDownloadPDF2Loading] = useState(false);
  const [percentPDF2, setPercentPDF2] = useState(0);
  const [isDownloadPDF3Loading, setIsDownloadPDF3Loading] = useState(false);
  const [percentPDF3, setPercentPDF3] = useState(0);
  const [tab, setTab] = useState(0);

  const [searchParam, setSearchParam] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderForEdit, setSelectedOrderForEdit] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmModalForEmail, setShowConfirmModalForEmail] =
    useState(false);
  const [showPdfDownloadModal, setShowPdfDownloadModal] = useState(false);
  const [showPdfAllDownloadModal, setShowPdfAllDownloadModal] = useState(false);
  const [showCostEstimateDownloadModal, setShowCostEstimateDownloadModal] =
    useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const { successAlert } = useContext(ConnectContext);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [orderId, setOrderId] = useState(null);
  const [
    showPdfDownloadModalForInsurance,
    setShowPdfDownloadModalForInsurance,
  ] = useState(false);
  const [showPdfDownloadModalForNew, setShowPdfDownloadModalForNew] =
    useState(false);

  const [sort, setSort] = useState({
    column: "createdAt",
    sortDirection: "desc",
  });
  const [result, setResult] = useState({
    users: [],
    count: 0,
    hasMore: false,
  });

  const columns = useMemo(() => [
    {
      selector: (row, index) => index + 1 + (curPage - 1) * 20,
      width: "50px",
    },
    {
      name: "Name",
      columnName: "firstname",
      selector: (row) => `${row.firstname} ${row.lastname}`,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Email",
      columnName: "email",
      selector: (row) => row.email,
      sortable: true,
      minWidth: "250px",
    },
    // {
    //   name: "Geschlecht",
    //   columnName: "type",
    //   selector: (row) => (row.type === "man" ? "Herr" : "Frau"),
    //   sortable: true,
    //   minWidth: "50px",
    // },
    // {
    //   name: "Pflegegrad",
    //   columnName: "degree",
    //   cell: (row) => (
    //     <div className="text-[10px]">
    //       {row.insurance === "Beihilfe Stelle 50%" ||
    //       row.insurance === "Beihilfe Stelle 70%" ? (
    //         <p className="px-2 py-1 text-[#f06548] rounded-sm bg-[rgba(240,101,72,.1)] font-bold">
    //           Rechnung beilegen
    //         </p>
    //       ) : row.degree == 0 ? (
    //         <p className="px-2 py-1 text-[#f06548] rounded-sm bg-[rgba(240,101,72,.1)] font-bold">
    //           Rechnung beilegen
    //         </p>
    //       ) : (
    //         <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">{`Pflegegrad ${row.degree}`}</p>
    //       )}
    //     </div>
    //   ),
    //   sortable: true,
    //   minWidth: "150px",
    // },
    {
      name: "Bestellrythmus",
      columnName: "interval",
      cell: (row) => (
        <div className="text-[10px]">
          {row.interval === "1" ? (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Monatlich
            </p>
          ) : row.interval == "2" ? (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Alle zwei Monate
            </p>
          ) : (
            <p className="px-2 py-1 text-[#0ab39c] rounded-sm bg-[rgba(10,179,156,.1)] font-bold">
              Alle drei Monate
            </p>
          )}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Versicherung",
      columnName: "insurance",
      selector: (row) => row.insurance,
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Vertragspartner Nr",
      columnName: "referral",
      selector: (row) => row.referral,
      sortable: false,
      minWidth: "50px",
    },
    {
      name: "Genehmigt am",
      columnName: "approvedOn",
      selector: (row) =>
        row.approvedOn ? moment(row.approvedOn).format("DD.MM.YYYY") : "",
      sortable: true,
    },
    ...(tab == 0
      ? [
          {
            name: "Erstellt Am",
            columnName: "createdAt",
            selector: (row) => moment(row.createdAt).format("DD.MM.YYYY"),
            sortable: true,
          },
        ]
      : []),
    ...(tab == 0
      ? [
          {
            name: "PG51",
            columnName: "pg51",
            selector: (row) => (row.pg51 ? "Genehmigt" : "Ausstehend"),
            sortable: true,
            minWidth: "50px",
          },
        ]
      : []),
    ...(tab == 0
      ? [
          {
            name: "PG54",
            columnName: "pg54",
            selector: (row) => (row.pg54 ? "Genehmigt" : "Ausstehend"),
            sortable: true,
            minWidth: "50px",
          },
        ]
      : []),
    {
      name: "Actions",
      columnName: "action",
      cell: (row) => (
        <div className="flex flex-row justify-end items-center space-x-2">
          {tab == 0 && currentUser?.role == "admin" && (
            <div
              title="Bestellung"
              className="cursor-pointer text-center"
              onClick={() => {
                setShowPdfDownloadModal(true);
                setOrderId(row.id);
              }}
            >
              <BsFilePdfFill className="text-[#6ea13b] text-[24px]" />
            </div>
          )}
          {currentUser?.role == "admin" && (
            <div
              title="Kostenvoranschlag"
              className="cursor-pointer text-center"
              onClick={() => {
                setShowPdfDownloadModalForInsurance(true);
                setOrderId(row.id);
              }}
            >
              <BsFilePdfFill className="text-[#0ab39c] text-[24px]" />
            </div>
          )}
          {tab == 0 && currentUser?.role == "admin" && (
            <div
              title="Wechselwunsch"
              className="cursor-pointer text-center"
              onClick={() => {
                setShowPdfDownloadModalForNew(true);
                setOrderId(row.id);
              }}
            >
              <BsFilePdfFill className="text-[#6ea13b] text-[24px]" />
            </div>
          )}
          {currentUser?.role == "admin" && (
            <BsFillFileEarmarkExcelFill
              title="Logistik"
              className="text-[24px] text-[#0ab39c] cursor-pointer"
              onClick={() => {
                let object = {
                  "order-id": "",
                  "buyer-phone-number": row.phone,
                  email: row.email,
                  "hs-code": "",
                  "recipent-name": row.firstname + " " + row.lastname,
                  "ship-street-1": row.street,
                  "ship-houseNo-1": row.houseNo,
                  "ship-city-1": row.location,
                  "ship-postal-code-1": row.postcode,
                  "ship-state": "",
                  "ship-address-2": row.street2,
                  "ship-houseNo-2": row.houseNo2,
                  "ship-city-2": row.location2,
                  "ship-postal-code-2": row.postcode2,
                  "ship-country": "Germany",
                };

                const orderProducts = (JSON.parse(row.products) || []).filter(
                  (curr) => {
                    if (
                      curr.title !==
                      "Saugende Bettschutzeinlage / wiederverwendbar"
                    ) {
                      return true;
                    }
                    const productOrderDate = curr.date
                      ? new Date(curr.date)
                      : new Date();
                    const today = new Date();
                    if (
                      productOrderDate.getFullYear() !== today.getFullYear()
                    ) {
                      return true;
                    }
                    return productOrderDate.getMonth() === today.getMonth();
                  }
                );
                if (orderProducts.length) {
                  for (let j = 0; j < Products.length; j++) {
                    let exist = orderProducts.some(
                      (order) => order.title === Products[j].title
                    );
                    if (exist) {
                      object[`product-name-${j + 1}`] = Products[j].title;
                      object[`quantity-to-ship-${j + 1}`] =
                        orderProducts.reduce(
                          (acc, cur) =>
                            cur.title === Products[j].title ? ++acc : acc,
                          0
                        );
                      object[`unit-price-${j + 1}`] = Products[j].price;
                      object[`Menge ${j + 1}`] = Products[j].stock;
                      if (
                        Products[j].title ===
                        "Saugende Bettschutzeinlage / wiederverwendbar"
                      ) {
                        const customAmount =
                          row.pg51 && typeof row.pg51Amount === "number"
                            ? `${row.pg51Amount} Stk.`
                            : Products[j].stock;
                        object[`Menge ${j + 1}`] = customAmount;
                        object[`quantity-to-ship-${j + 1}`] = "3 Stk.";
                      }
                      object[`product-barcode-${j + 1}`] = ProductsBarcode[j];
                      if (Products[j].title === "Fingerlinge") {
                        object["Fingerlinge Größe"] = orderProducts.reduce(
                          (acc, cur) =>
                            cur.title === Products[j].title
                              ? `${cur.size}, ${acc}`
                              : acc,
                          ""
                        );
                      }
                      if (Products[j].title === "Einmalhandschuhe") {
                        object["Einmalhandschuhe Größe"] = orderProducts.reduce(
                          (acc, cur) =>
                            cur.title === Products[j].title
                              ? `${cur.size}, ${acc}`
                              : acc,
                          ""
                        );
                      }
                    }
                  }
                }
                const ws = XLSX.utils.json_to_sheet([object]);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, {
                  bookType: "xlsx",
                  type: "array",
                });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(
                  data,
                  `Excel-Sheet-${row.firstname}` + fileExtension
                );
              }}
            />
          )}
          <BsFillSendFill
            title="Erneut senden"
            className="text-[20px] text-[#6ea13b] cursor-pointer"
            onClick={() => {
              setSelectedOrder(row.id);
              setShowConfirmModalForEmail(true);
            }}
          />
          <BsPencilFill
            title="Bearbeiten"
            className="text-[#0ab39c] text-[19px] cursor-pointer"
            onClick={() => {
              setSelectedOrderForEdit(row);
              setShowEditModal(true);
            }}
          />
          {currentUser?.role === "admin" && (
            <BsTrash
              title="Löschen"
              className="text-red-600 text-[20px] cursor-pointer"
              onClick={() => {
                setSelectedOrder(row.id);
                setShowConfirmModal(true);
              }}
            />
          )}
        </div>
      ),
      sortable: false,
      right: true,
      minWidth: "210px",
    },
  ]);

  useEffect(() => {
    fetchOrders();
  }, [sort, searchParam, curPage, tab]);

  const fetchOrders = () => {
    setIsLoading(true);
    api
      .post("/api/order/getAll", {
        searchParam: searchParam,
        pageSize: 20,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
        tab,
      })
      .then((res) => {
        setResult(res.data);
        setIsLoading(false);
      });
  };

  const handleDeleteOrder = () => {
    if (selectedOrder) {
      setIsRemoveLoading(true);
      api.delete(`/api/order/remove/${selectedOrder}`).then((res) => {
        successAlert("Bestellung wurde erfolgreich gelöscht!");
        setIsRemoveLoading(false);
        setShowConfirmModal(false);
        fetchOrders();
      });
    }
  };

  const handleSendEmail = () => {
    if (selectedOrder) {
      setIsRemoveLoading(true);
      api.put(`/api/order/resend/${selectedOrder}`).then((res) => {
        successAlert("E-Mail erneut gesendet!");
        setIsRemoveLoading(false);
        setShowConfirmModalForEmail(false);
      });
    }
  };

  const handlePageChange = (page) => {
    setCurPage(page);
  };

  const handleSort = (column, sortDirection) => {
    setSort({
      column: column.columnName,
      sortDirection,
    });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async (fileName) => {
    setIsDownloadLoding(true);
    api
      .post("/api/order/getAll", {
        searchParam: searchParam,
        pageSize: 10000,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
        tab: tab,
      })
      .then((res) => {
        if (res.data.users) {
          let excelData = [];
          for (let i = 0; i < res.data.users.length; i++) {
            let insuranceObj = Insurances.find(
              (obj) => obj.name === res.data.users[i].insurance
            );
            // console.log("insuranceObj", insuranceObj);
            let object = {
              Anrede: res.data.users[i].type === "man" ? "Herr" : "Frau",
              Email: res.data.users[i].email,
              Nachname: res.data.users[i].lastname,
              Vorname: res.data.users[i].firstname,
              Geschlecht:
                res.data.users[i].type === "man" ? "männlich" : "weiblich",
              "geb. am": res.data.users[i].startDate,
              Alter: getAge(res.data.users[i].startDate),
              Kunde: res.data.users[i].type === "man" ? "Kunde" : "Kundin",
              Sehr:
                res.data.users[i].type === "man"
                  ? "Sehr geehrter"
                  : "Sehr geehrte",
              Pflegegrad: res.data.users[i].degree,
              Straße: res.data.users[i].street,
              Hausnummer: res.data.users[i].houseNo,
              PLZ: res.data.users[i].postcode,
              Ort: res.data.users[i].location,
              "Name/Firma": res.data.users[i].company,
              Straße2: res.data.users[i].street2,
              Hausnummer2: res.data.users[i].houseNo2,
              PLZ2: res.data.users[i].postcode2,
              Ort2: res.data.users[i].location2,
              Telefon: res.data.users[i].phone,
              Versichertennummer: res.data.users[i].insuranceNo,
              "Versicherung/Krankenkasse": res.data.users[i].health,
              "IK der Pflegekasse": insuranceObj?.ik,
              "Anschrift + Telefon der Kasse": insuranceObj?.address,
              "Vertragspartner Nr": res.data.users[i].referral,
              Intervall: res.data.users[i].interval
                ? res.data.users[i].interval
                : "1",
            };
            const orderProducts = (
              JSON.parse(res.data.users[i].products) || []
            ).filter((curr) => {
              if (
                curr.title !== "Saugende Bettschutzeinlage / wiederverwendbar"
              ) {
                return true;
              }
              const productOrderDate = curr.date
                ? new Date(curr.date)
                : new Date();
              const today = new Date();
              if (productOrderDate.getFullYear() !== today.getFullYear()) {
                return true;
              }
              return productOrderDate.getMonth() === today.getMonth();
            });
            if (orderProducts.length) {
              for (let j = 0; j < Products.length; j++) {
                object[Products[j].title] = orderProducts.reduce(
                  (acc, cur) => (cur.title === Products[j].title ? ++acc : acc),
                  0
                );
                object[`Preis ${j + 1}`] = Products[j].price;
                object[`Preis*Anzahl ${j + 1}`] =
                  Number(Products[j].price) *
                  orderProducts.reduce(
                    (acc, cur) =>
                      cur.title === Products[j].title ? ++acc : acc,
                    0
                  );
                object[`Menge ${j + 1}`] = Products[j].stock;
                if (
                  Products[j].title ===
                  "Saugende Bettschutzeinlage / wiederverwendbar"
                ) {
                  object[`Preis*Anzahl ${j + 1}`] =
                    Number(Products[j].price) * res.data.users[i].pg51Amount;
                  object[Products[j].title] = res.data.users[i].pg51Amount;
                  object[`Menge ${j + 1}`] = "1 Stk.";
                }
                if (Products[j].title === "Fingerlinge") {
                  object["Fingerlinge Größe"] = orderProducts.reduce(
                    (acc, cur) =>
                      cur.title === Products[j].title
                        ? `${cur.size}, ${acc}`
                        : acc,
                    ""
                  );
                }
                if (Products[j].title === "Einmalhandschuhe") {
                  object["Einmalhandschuhe Größe"] = orderProducts.reduce(
                    (acc, cur) =>
                      cur.title === Products[j].title
                        ? `${cur.size}, ${acc}`
                        : acc,
                    ""
                  );
                }
              }
              object["Gesamtpreis"] = orderProducts.reduce(
                (n, { title, price }) => {
                  if (
                    title === "Saugende Bettschutzeinlage / wiederverwendbar"
                  ) {
                    return n + Number(price) * res.data.users[i].pg51Amount;
                  }
                  return n + price;
                },
                0
              );
            }
            excelData.push(object);
          }
          const ws = XLSX.utils.json_to_sheet(excelData);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
          setIsDownloadLoding(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setIsDownloadLoding(false);
      });
  };

  const generateAndDownloadZip1 = async (selectedDate) => {
    setIsDownloadPDF1Loading(true);
    setPercentPDF1(0);
    const zip = new JSZip();

    api
      .post("/api/order/getAll", {
        searchParam: searchParam,
        pageSize: 10000,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
        tab: tab,
        from: "all",
      })
      .then(async (res) => {
        if (res.data.users) {
          const data = res.data.users;
          for (const [index, row] of data.entries()) {
            const pdfBlob = await pdf(
              <OrderPdfDocument row={row} selectedDate={selectedDate} />
            ).toBlob();
            zip.file(
              `Bestellung${row.insuranceNo ? `-${row.insuranceNo}` : ""}-${
                row.id
              }.pdf`,
              pdfBlob
            );

            const percent = Number((index / data.length) * 100).toFixed(2);
            setPercentPDF1(percent);
          }

          const zipBlob = await zip.generateAsync({ type: "blob" });
          saveAs(zipBlob, "all-pdfs-order.zip");
          setIsDownloadPDF1Loading(false);
        }
      });
  };

  const generateAndDownloadZip2 = async (selectedDate) => {
    setIsDownloadPDF2Loading(true);
    setPercentPDF2(0);
    const zip = new JSZip();

    api
      .post("/api/order/getAll", {
        searchParam: searchParam,
        pageSize: 10000,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
        tab: tab,
        from: "all",
      })
      .then(async (res) => {
        if (res.data.users) {
          const data = res.data.users;
          for (const [index, row] of data.entries()) {
            const pdfBlob = await pdf(
              <InsurancePdfDocument row={row} selectedDate={selectedDate} />
            ).toBlob();
            zip.file(
              `Kostenvoranschlag${
                row.insuranceNo ? `-${row.insuranceNo}` : ""
              }-${row.id}.pdf`,
              pdfBlob
            );

            const percent = Number((index / data.length) * 100).toFixed(2);
            setPercentPDF2(percent);
          }

          const zipBlob = await zip.generateAsync({ type: "blob" });
          saveAs(zipBlob, "all-pdfs-insurance.zip");
          setIsDownloadPDF2Loading(false);
        }
      });
  };

  const generateAndDownloadZip3 = async () => {
    setIsDownloadPDF3Loading(true);
    setPercentPDF3(0);
    const zip = new JSZip();

    api
      .post("/api/order/getAll", {
        searchParam: searchParam,
        pageSize: 10000,
        pageNumber: curPage,
        column: sort.column,
        sortDirection: sort.sortDirection,
        tab: tab,
        from: "all",
      })
      .then(async (res) => {
        if (res.data.users) {
          const data = res.data.users;
          for (const [index, row] of data.entries()) {
            const pdfBlob = await pdf(<NewPdfDocument row={row} />).toBlob();
            zip.file(
              `Wechselwunsch${row.insuranceNo ? `-${row.insuranceNo}` : ""}-${
                row.id
              }.pdf`,
              pdfBlob
            );

            const percent = Number((index / data.length) * 100).toFixed(2);
            setPercentPDF3(percent);
          }

          const zipBlob = await zip.generateAsync({ type: "blob" });
          saveAs(zipBlob, "all-pdfs-third.zip");
          setIsDownloadPDF3Loading(false);
        }
      });
  };

  const handleCloseOrderModal = () => {
    setShowEditModal(false);
    setSelectedOrderForEdit(null);
  };
  return (
    <div className="w-[100%] h-[100%] overflow-x-hidden bg-[#f4f6fa]">
      <Header />
      <div className="max-w-[1800px] pt-[120px] mx-auto h-full flex flex-col justify-start items-start bg-white px-12 -md:px-4 overflow-y-auto profile-container min-h-[calc(100vh-116px)] detail-modal">
        <div className="w-full flex flex-row justify-center">
          <div className="w-full max-w-md px-2 pb-12">
            <Tab.Group onChange={(index) => setTab(index)}>
              <Tab.List className="flex space-x-1 rounded-xl p-1 from-[#b2d98b] to-[#4f7d22] bg-gradient-to-r">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white text-[#6ea13b] shadow"
                        : "text-white hover:bg-white/[0.12]"
                    )
                  }
                >
                  <p>Alle</p> Kunden
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white text-[#6ea13b] shadow"
                        : "text-white hover:bg-white/[0.12]"
                    )
                  }
                >
                  Versand Monatsmitte
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-medium leading-5",
                      "ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                      selected
                        ? "bg-white text-[#6ea13b] shadow"
                        : "text-white hover:bg-white/[0.12]"
                    )
                  }
                >
                  Versand Monatsende
                </Tab>
              </Tab.List>
            </Tab.Group>
          </div>
        </div>
        <div className="w-full flex flex-row -lg:flex-col -lg:space-y-2 justify-between items-center mb-4">
          <span className="text-[26px] font-RNSSanzBold text-[#3D474D] mb-2 -lg:w-full font-bold">
            Auftragsverwaltung
          </span>
          <div className="flex -lg:flex-col items-center -lg:items-start -lg:space-x-0 -lg:space-y-2 space-x-2 -lg:w-full">
            <div className="flex flex-row justify-start items-center space-x-2">
              <div
                className="px-4 py-2 text-white rounded-lg text-[12px] bg-[#6ea13b] cursor-pointer text-center flex justify-center items-center space-x-2"
                onClick={() => setShowPdfAllDownloadModal(true)}
              >
                {isDownloadPDF1Loading ? (
                  <>
                    <CgSpinner
                      className={`text-white text-[20px] animate-spin`}
                    />
                    <p className="text-white whitespace-nowrap">
                      {percentPDF1} %
                    </p>
                  </>
                ) : (
                  <p className="whitespace-nowrap">Bestellungen</p>
                )}
              </div>
              {currentUser?.role == "admin" && (
                <div
                  className="px-4 py-2 text-white rounded-lg text-[12px] bg-[#6ea13b] cursor-pointer text-center flex justify-center items-center space-x-2"
                  onClick={() => {
                    setShowCostEstimateDownloadModal(true);
                  }}
                >
                  {isDownloadPDF2Loading ? (
                    <>
                      <CgSpinner
                        className={`text-white text-[20px] animate-spin`}
                      />
                      <span className="text-white whitespace-nowrap">
                        {percentPDF2} %
                      </span>
                    </>
                  ) : (
                    <p className="whitespace-nowrap">Kostenvoranschläge</p>
                  )}
                </div>
              )}
              {currentUser?.role == "admin" && (
                <div
                  className="px-4 py-2 text-white rounded-lg text-[12px] bg-[#6ea13b] cursor-pointer text-center flex justify-center items-center space-x-2"
                  onClick={generateAndDownloadZip3}
                >
                  {isDownloadPDF3Loading ? (
                    <>
                      <CgSpinner
                        className={`text-white text-[20px] animate-spin`}
                      />
                      <span className="text-white whitespace-nowrap">
                        {percentPDF3} %
                      </span>
                    </>
                  ) : (
                    <p className="whitespace-nowrap">Wechselwünsche</p>
                  )}
                </div>
              )}
            </div>
            {currentUser?.role == "admin" && (
              <div
                className="px-4 py-2 text-white rounded-lg text-[12px] bg-[#6ea13b] cursor-pointer text-center"
                onClick={() => {
                  exportToExcel("Excel-Sheet-All");
                }}
              >
                {isDownloadLoading ? (
                  <CgSpinner
                    className={`text-white text-[20px] animate-spin`}
                  />
                ) : (
                  <p className="whitespace-nowrap">Excel-Sheets</p>
                )}
              </div>
            )}
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                id="search"
                name="search"
                type="search"
                value={searchParam}
                onInput={(e) => setSearchParam(e.target.value)}
                placeholder="Suchen..."
                className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-[#6ea13b] focus:outline-none block w-full pl-10 p-1.5`}
              />
            </div>
          </div>
        </div>
        <div className="w-[100%]">
          <DataTable
            columns={columns}
            data={result.users}
            pagination
            paginationServer
            paginationTotalRows={result.count}
            progressPending={isLoading}
            progressComponent={<CustomLoader />}
            paginationPerPage={20}
            paginationRowsPerPageOptions={[20]}
            onChangePage={handlePageChange}
            sortServer
            onSort={handleSort}
            noDataComponent={"Es gibt keine Datensätze zum Anzeigen"}
          />
        </div>
      </div>
      <Footer />
      {showConfirmModal && (
        <ConfirmModal
          title={"Bestellung entfernen"}
          description={"Möchten Sie diesen Auftrag entfernen?"}
          confirmTitle={"Löschen"}
          cancelTitle={"Abbrechen"}
          handleConfirm={() => handleDeleteOrder()}
          handleCancel={() => setShowConfirmModal(false)}
          isLoading={isRemoveLoading}
        />
      )}
      {showConfirmModalForEmail && (
        <ConfirmModal
          title={"E-Mail erneut senden"}
          description={
            "Möchten Sie für diese Bestellung erneut eine E-Mail versenden?"
          }
          confirmTitle={"Senden Sie"}
          cancelTitle={"Abbrechen"}
          handleConfirm={() => handleSendEmail()}
          handleCancel={() => setShowConfirmModalForEmail(false)}
          isLoading={isRemoveLoading}
        />
      )}
      {showEditModal && (
        <OrderModal
          onClose={() => handleCloseOrderModal()}
          selectedOrder={selectedOrderForEdit}
          fetchOrders={() => fetchOrders()}
        />
      )}
      {showPdfDownloadModal && orderId && (
        <PDFDownloadModal
          handleCancel={() => {
            setShowPdfDownloadModal(false);
            setOrderId(null);
          }}
          // handleConfirm={() => {
          //   setShowPdfDownloadModal(false);
          //   setSelectedOrderForEdit(null);
          // }}
          orderId={orderId}
        />
      )}
      {showCostEstimateDownloadModal && (
        <PDFAllDownloadModal
          handleCancel={() => setShowCostEstimateDownloadModal(false)}
          handleConfirm={(selectedDate) => {
            generateAndDownloadZip2(selectedDate);
            setShowCostEstimateDownloadModal(false);
          }}
        />
      )}
      {showPdfAllDownloadModal && (
        <PDFAllDownloadModal
          handleCancel={() => setShowPdfAllDownloadModal(false)}
          handleConfirm={(selectedDate) => {
            generateAndDownloadZip1(selectedDate);
            setShowPdfAllDownloadModal(false);
          }}
        />
      )}
      {showPdfDownloadModalForInsurance && orderId && (
        <PDFDownloadModalForInsurance
          handleCancel={() => {
            setShowPdfDownloadModalForInsurance(false);
            setOrderId(null);
          }}
          orderId={orderId}
        />
      )}
      {showPdfDownloadModalForNew && orderId && (
        <PDFDownloadModalForNew
          handleCancel={() => {
            setShowPdfDownloadModalForNew(false);
            setOrderId(null);
          }}
          orderId={orderId}
        />
      )}
    </div>
  );
};

export default OrderList;
