import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import {
  Table,
  TableHeader,
  TableBody,
  TableCell,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import CheckPNG from "../../../assets/check.png";
import moment from "moment";
import { Products } from "../../../pages/components/Products";
import { capitalize } from "../../../utils/str";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    padding: "30px 60px",
  },
  rowAlignCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingBottom: "10px",
  },
  rowAlignStart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "10px",
    flexWrap: "wrap",
  },
  colJustifyStart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: "10px",
  },
  colJustifyCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingBottom: "10px",
  },
  txtTitle: {
    fontSize: "8px",
    paddingBottom: "5px",
  },
  txtValue: {
    fontSize: "8px",
    fontWeight: 800,
    paddingBottom: "5px",
  },
  tableHeader: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: "10px",
    padding: "5px 5px",
  },
  tableText: {
    fontSize: "8px",
    padding: "10px 5px",
  },
});

const InsurancePdfDocument = ({ row, selectedDate }) => {
  useEffect(() => {
    return () => {
      orderProducts = {};
      data = [];
      specialData = [];
      grandTotalWithTax = 0;
      grandTotalWithoutTax = 0;
    };
  }, []);

  var orderProducts = {};
  JSON.parse(row.products).map((product) => {
    if (orderProducts[product.title]) {
      orderProducts[product.title] = {
        ...orderProducts[product.title],
        qty: orderProducts[product.title].qty + 1,
      };
    } else {
      product["qty"] = 1;
      orderProducts[product.title] = product;
    }
  });
  var data = [];
  var specialData = [];
  var grandTotalWithoutTax = 0;
  var grandTotalWithTax = 0;
  Products.map((item) => {
    var qty = 0;
    Object.entries(orderProducts).map((product, i) => {
      if (item.title == product[0]) {
        qty = product[1].qty;

        const isPg51 = item.barcode === "51.40.01.4";

        let withoutTaxAttribution =
          (item.bundle_qty / item.min_stock) *
          item.price_without_tax *
          product[1].qty;
        if (isPg51 && typeof row.pg51Amount === "number") {
          withoutTaxAttribution *= row.pg51Amount;
        }

        grandTotalWithoutTax += withoutTaxAttribution;

        let withTaxAttribution = item.min_price * product[1].qty;
        if (isPg51 && typeof row.pg51Amount === "number") {
          withTaxAttribution *= row.pg51Amount;
        }

        grandTotalWithTax += withTaxAttribution;
      }
    });
    if (item.title == "Saugende Bettschutzeinlage / wiederverwendbar") {
      var newItem = item;
      newItem["qty"] = qty;
      specialData.push({
        ...newItem,
      });
    } else {
      var newItem = item;
      newItem["qty"] = qty;
      data.push({
        ...newItem,
      });
    }
  });
  specialData.push({
    title: "Gesamtpreis",
    totalWithoutTax: grandTotalWithoutTax,
    totalWithTax: grandTotalWithTax,
  });
  let createdAt = moment(selectedDate ? selectedDate : new Date()).format(
    "MMMM YYYY"
  );
  return (
    // <PDFViewer width={"100%"} height={1200}>
    <Document>
      <Page style={styles.page}>
        <View
          style={{
            ...styles.rowAlignStart,
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            Anlage 3 - Erklärung zum Erhalt von Pflegehilfsmitteln
            (Empfangsbestätigung)
          </Text>
        </View>
        <View style={{ ...styles.rowAlignStart, paddingBottom: 0 }}>
          {row.insuranceNo ? (
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  ...styles.txtTitle,
                  fontSize: 10,
                  width: "100%",
                  borderBottom: 1,
                }}
              >
                {capitalize(row.insurance)}
              </Text>
              <Text
                style={{
                  ...styles.txtTitle,
                  marginTop: 1,
                  marginLeft: 1,
                  marginBottom: "10px",
                }}
              >
                (Name der Pflegekasse)
              </Text>
            </View>
          ) : null}
          <View style={{ width: "100%" }}>
            <Text
              style={{
                ...styles.txtTitle,
                fontSize: 10,
                width: "100%",
                borderBottom: 1,
              }}
            >
              {row.lastname + " " + row.firstname};{" "}
              {`${row.street} ${row.houseNo}, ${row.postcode} ${row.location}`};{" "}
              {row.phone}
              {/* {row.street +
                ", " +
                row.houseNo +
                " " +
                row.postcode +
                " " +
                row.location}
              ; {row.phone} */}
            </Text>
            <Text
              style={{
                ...styles.txtTitle,
                marginTop: 1,
                marginLeft: 1,
                marginBottom: "10px",
              }}
            >
              (Name, Anschrift und Telefonnummer des Versicherten, ggf. eines
              Ansprechpartners)
            </Text>
          </View>
          {row.insuranceNo && row.insurance !== "privat" && (
            <View style={{ width: "100%" }}>
              <Text
                style={{
                  ...styles.txtTitle,
                  fontSize: 10,
                  width: "100%",
                  borderBottom: 1,
                }}
              >
                {row.insuranceNo}
              </Text>
              <Text
                style={{
                  ...styles.txtTitle,
                  marginTop: 1,
                  marginLeft: 1,
                  marginBottom: "10px",
                }}
              >
                (Krankenversichertennummer bzw. Pflegeversichertennummer)
              </Text>
            </View>
          )}
          <View style={{ width: "100%" }}>
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                borderBottom: 1,
              }}
            >
              <Text style={{ ...styles.txtTitle, fontSize: 10, width: "100%" }}>
                Pflege im Karton/ inobis Care GmbH
              </Text>
              <Text
                style={{
                  ...styles.txtTitle,
                  marginTop: 1,
                  marginLeft: 1,
                  textAlign: "right",
                  // marginBottom: "10px",
                }}
              >
                330 508 979
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  ...styles.txtTitle,
                  marginTop: 1,
                  marginLeft: 1,
                  marginBottom: "10px",
                }}
              >
                (Name des Leistungserbringers)
              </Text>
              <Text
                style={{
                  ...styles.txtTitle,
                  marginTop: 1,
                  marginLeft: 1,
                  textAlign: "right",
                  marginBottom: "10px",
                }}
              >
                (IK des Leistungserbringers)
              </Text>
            </View>
          </View>
          <View style={{ width: "100%" }}>
            <Text
              style={{
                ...styles.txtTitle,
                fontSize: 10,
                width: "100%",
                borderBottom: 1,
              }}
            >
              Bergmannstraße 2a 46045 Oberhausen
            </Text>
            <Text
              style={{
                ...styles.txtTitle,
                marginTop: 1,
                marginLeft: 1,
                marginBottom: "10px",
              }}
            >
              (Anschrift und Telefonnummer des Leistungserbringers)
            </Text>
          </View>
          <View style={{ width: "100%" }}>
            <Text
              style={{
                ...styles.txtTitle,
                fontSize: 10,
                width: "100%",
                borderBottom: 1,
              }}
            >
              {createdAt}
            </Text>
            <Text
              style={{
                ...styles.txtTitle,
                marginTop: 1,
                marginLeft: 1,
                marginBottom: "10px",
              }}
            >
              (Versorgungsmonat)
            </Text>
          </View>
        </View>
        <View style={{ margin: "20px 0" }}>
          <Text style={{ ...styles.txtTitle, lineHeight: "1.5" }}>
            Der zuvor genannte Leistungserbringer hat mir heute im
            augenscheinlich hygienischen und einwandfreien Zustand nachfolgend
            aufgeführte Pflegehilfsmittel übergeben sowie mich – soweit
            erforderlich – in den Gebrauch des Pflegehilfsmittels eingewiesen.
          </Text>
        </View>
        <View style={styles.colJustifyStart}>
          <View style={{ padding: "0px 0px 10px 0px", width: "100%" }}>
            <Table>
              <TableHeader textAlign={"center"}>
                <TableCell weighting={0.33} style={styles.tableHeader}>
                  Bezeichnung
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Pflegehilfsmittelpositionsnummer
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Stückzahl bzw. Packungen
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Gesamtpreis ohne MwSt.
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Gesamtpreis mit MwSt.
                </TableCell>
              </TableHeader>
            </Table>
            <Table data={data} renderTopBorder={false}>
              <TableHeader textAlign={"center"}>
                <TableCell
                  weighting={1}
                  style={{
                    ...styles.tableHeader,
                    textAlign: "center",
                    padding: "10px 5px",
                  }}
                >
                  Zum Verbrauch bestimmte Pflegehilfsmittel (PG 54)
                </TableCell>
              </TableHeader>
              <TableBody renderTopBorder={false}>
                <DataTableCell
                  weighting={0.33}
                  getContent={(r) => r.title}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => r.barcode}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => {
                    if (
                      r.barcode == "54.99.02.0001" ||
                      r.barcode == "54.99.02.0002"
                    ) {
                      return (
                        <Text>
                          {r.qty > 0 ? r.bundle_qty * r.qty : r.bundle_qty} ml
                        </Text>
                      );
                    } else {
                      return (
                        <Text>
                          {r.qty > 0 ? r.bundle_qty * r.qty : r.bundle_qty}{" "}
                          Stück
                        </Text>
                      );
                    }
                  }}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) =>
                    r.qty > 0
                      ? (
                          (r.bundle_qty / r.min_stock) *
                          r.price_without_tax *
                          r.qty
                        ).toFixed(2)
                      : ""
                  }
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) =>
                    r.qty > 0 ? (r.min_price * r.qty).toFixed(2) : ""
                  }
                  style={styles.tableText}
                />
              </TableBody>
            </Table>
          </View>

          <View
            break={true}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "15px",
            }}
          >
            <View
              style={{
                border: "1px solid black",
                padding: "1px",
                marginRight: "5px",
              }}
            >
              <Image
                source={CheckPNG}
                style={{ width: "8px", height: "8px" }}
              />
            </View>
            <Text
              style={{
                ...styles.txtTitle,
                lineHeight: "1.5",
                paddingBottom: 0,
              }}
            >
              Pflegehilfsmittel zur Körperpflege/Körperhygiene (PG 51) unter
              Abzug der gesetzlichen Zuzahlung, soweit keine Befreiung vorliegt.
            </Text>
          </View>

          <View style={{ padding: "0px 0px 10px 0px", width: "100%" }}>
            <Table>
              <TableHeader textAlign={"center"}>
                <TableCell weighting={0.33} style={styles.tableHeader}>
                  Bezeichnung
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Pflegehilfsmittelpositionsnummer
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Stückzahl bzw. Packungen
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Gesamtpreis ohne MwSt.
                </TableCell>
                <TableCell weighting={0.16} style={styles.tableHeader}>
                  Gesamtpreis mit MwSt.
                </TableCell>
              </TableHeader>
            </Table>
            <Table data={specialData}>
              <TableHeader textAlign={"center"}>
                <TableCell
                  weighting={1}
                  style={{
                    ...styles.tableHeader,
                    textAlign: "center",
                    padding: "10px 5px",
                  }}
                >
                  Pflegehilfsmittel zur Körperpflege/Körperhygiene (PG 51)
                </TableCell>
              </TableHeader>
              <TableBody renderTopBorder={false}>
                <DataTableCell
                  weighting={0.33}
                  getContent={(r) => r.title}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => r.barcode}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => {
                    const isPg51 = r.barcode === "51.40.01.4";
                    let amount = r.qty > 0 ? r.bundle_qty * r.qty : r.min_stock; // Took from the old code
                    if (isPg51 && typeof row.pg51Amount === "number") {
                      amount = row.pg51Amount;
                    }

                    return r.totalWithTax ? "" : <Text>{amount} Stück</Text>;
                  }}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => {
                    const price =
                      r.qty > 0
                        ? (
                            (r.bundle_qty / r.min_stock) *
                            r.price_without_tax *
                            r.qty
                          ).toFixed(2)
                        : r.totalWithoutTax
                        ? r.totalWithoutTax.toFixed(2)
                        : "";

                    const isPg51 = r.barcode === "51.40.01.4";

                    if (isPg51 && typeof row.pg51Amount === "number") {
                      return price * row.pg51Amount;
                    }

                    return price;
                  }}
                  style={styles.tableText}
                />
                <DataTableCell
                  weighting={0.16}
                  getContent={(r) => {
                    const isPg51 = r.barcode === "51.40.01.4";

                    const price =
                      r.qty > 0
                        ? (r.min_price * r.qty).toFixed(2)
                        : r.totalWithTax
                        ? r.totalWithTax.toFixed(2)
                        : "";

                    if (isPg51 && typeof row.pg51Amount === "number") {
                      return price * row.pg51Amount;
                    }

                    return price;
                  }}
                  style={styles.tableText}
                />
              </TableBody>
            </Table>
          </View>

          <Text
            style={{
              ...styles.txtTitle,
              lineHeight: "1.5",
              paddingTop: 5,
              paddingBottom: 0,
              textAlign: "justify",
            }}
          >
            Ich darf die überlassenen Pflegehilfsmittel keinem Dritten
            verleihen, übereignen oder verpfänden. Ich bin darüber aufgeklärt
            worden, dass die Pflegekasse die Kosten nur für solche
            Pflegehilfsmittel und in dem finanziellen Umfang übernimmt, für die
            ich eine Kostenübernahmeerklärung durch die Pflegekasse erhalten
            habe. Kosten für evtl. darüberhinausgehende Leistungen sind von mir
            selbst zu tragen. Eine Durchschrift dieser Erklärung habe ich
            erhalten. Weiterhin bin ich darauf hingewiesen worden, dass ich die
            erhaltenen Produkte ausnahmslos für die häusliche Pflege durch eine
            private Pflegeperson (und nicht durch Pflegedienste oder
            Einrichtungen der Tagespflege) verwenden darf.
          </Text>

          <View
            style={{
              ...styles.rowAlignStart,
              justifyContent: "flex-start",
              width: "100%",
              paddingBottom: 0,
            }}
          >
            {row.insurance !== "privat" ? (
              <View style={{ ...styles.colJustifyCenter }}>
                <Image
                  source={row.signImage}
                  style={{ height: 60, marginBottom: "-10px" }}
                />
                <Text>___________________</Text>
                <Text
                  style={{
                    ...styles.txtTitle,
                    width: "100%",
                    textAlign: "left",
                    paddingTop: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  Datum und Unterschrift der/des Versicherten*
                </Text>
                <Text
                  style={{
                    ...styles.txtTitle,
                    width: "100%",
                    textAlign: "left",
                    paddingTop: "5px",
                    fontSize: "8px",
                  }}
                >
                  *Unterschrift der Betreuungsperson oder des gesetzlichen
                  Vertreters bei Personen, die das 18. Lebensjahr noch nicht
                  vollendet haben
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </Page>
    </Document>
    // </PDFViewer>
  );
};

export default InsurancePdfDocument;
